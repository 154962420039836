import { TermsPayment } from '@/settings/domain/ParmsGeneral/termsPayment/TermsPayment';
import { TypeDocument } from '@/settings/domain/TypeDocument/TypeDocument';
import { Currency } from '@/settings/domain/currency/Currency';
import { CurrencyFactor } from '@/settings/domain/currencyfactor/CurrencyFactor';
import { Store } from '@/wms/domain/layout/store/Store';
import { OrderLine } from '../orderline/OrderLine';
import { Entity } from '@/settings/domain/entity/entity/Entity';
import { Warehouse } from '@/settings/domain/warehouse/Warehouse';
import { Taxe } from '@/settings/domain/taxe/Taxe';
import { Process } from '@/tracking/domain/processtype/Process';
import { Switches } from '@/tracking/domain/switches/Switches';
import { ContableConcepts } from '../ContableConcepts/ContableConcepts';
import { House } from '../House/House';
import { PreRegisterForMultiselect } from '@/wms/domain/preRegister/PreRegisterForMultiselect';

export class DocumentHeader {
  number: number;
  type: string;
  documentType: TypeDocument | null;
  contableConcept: ContableConcepts | null;
  date: Date | string;
  time: string;
  dueDate: Date | string;
  total: number;
  freight: number;
  condition: TermsPayment | null;
  cost: number;
  vendor: string;
  appliedValue: number;
  model: string;
  duration: number;
  concept: string;
  document: number;
  budgetExpiration: string;
  exported: boolean;
  prefix: string;
  currency: Currency | null;
  currencyFactor: CurrencyFactor | null;
  rate: number;
  storeDestination: Store | null;
  docReference: string;

  // Campos para guardar el número de orden proveniente
  orderNumber: number;
  orderType: string;
  merchandiseValue: number;
  active: boolean;
  sw: Switches | null;

  // Campos para guardar el tipo de proceso
  processType: Process | null;
  warehouse: Warehouse | null;
  store: Store | null;
  forBondedEntry: boolean;
  bondedEntryNumber: string;

  // Campos para guardar el cliente del documento
  customer: Entity | null;
  createAt: string;
  updateAt: string;
  tax: Taxe | null;
  specialNoteType: string;
  typeInvoicing: string;
  house: House | null;
  master: PreRegisterForMultiselect | null;

  lines: OrderLine[];

  public constructor() {
    this.number = 0;
    this.type = '';
    this.documentType = null;
    this.contableConcept = null;
    this.date = '';
    this.time = '';
    this.dueDate = '';
    this.total = 0;
    this.freight = 0;
    this.condition = null;
    this.cost = 0;
    this.vendor = '';
    this.appliedValue = 0;
    this.model = '';
    this.duration = 0;
    this.concept = '';
    this.document = 0;
    this.budgetExpiration = '';
    this.exported = false;
    this.prefix = '';
    this.currency = null;
    this.currencyFactor = null;
    this.rate = 0;
    this.storeDestination = null;
    this.docReference = '';

    // Campos para guardar el número de orden proveniente
    this.orderNumber = 0;
    this.orderType = '';
    this.merchandiseValue = 0;
    this.active = true;
    this.sw = null;

    // Campos para guardar el tipo de proceso
    this.processType = null;
    this.warehouse = null;
    this.store = null;
    this.forBondedEntry = false;
    this.bondedEntryNumber = '';

    // Campos para guardar el cliente del documento
    this.customer = null;
    this.createAt = '';
    this.updateAt = '';
    this.tax = null;
    this.specialNoteType = '';
    this.typeInvoicing = 'COMERCIAL';
    this.house = null;
    this.master = null;

    this.lines = [];
  }
}
